/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createInstanceProvisioningJobWithPaymentIntentId } from '../api/instance-provisioning-jobs';
import useAuthStore from '../store/AuthenticationStore';
import RotatingLinesLoader from '../common/components/spinners/RotatingLinesLoader';
import HeaderComponent from '../common/layout/HeaderComponent';
import FooterComponent from '../common/layout/FooterComponent';
import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from '@aws-amplify/auth';
import { UserDetails } from '../types/UserDetails.type';

const PaymentCallbackPage = () => {
  const location = useLocation();
  const payment_intent = new URLSearchParams(location.search).get(
    'payment_intent'
  );

  const { setUserDetails, authToken, userDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    authToken: string;
    userDetails: UserDetails;
  };

  const payment_intent_ref = useRef(payment_intent);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const isCancelledRef = useRef(false);

  async function getUpdatedUserAttributes() {
    const updatedUserAttributes: any = await fetchUserAttributes();
    setUserDetails({ ...userDetails, ...updatedUserAttributes });
  }
  useEffect(() => {
    getUpdatedUserAttributes();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchAccountData = async () => {
      const response = await createInstanceProvisioningJobWithPaymentIntentId(
        payment_intent_ref?.current ?? '',
        authToken
      );

      if (!isCancelledRef.current && response.id) {
        setLoading(false);
        navigate(`/instance-provisioning-jobs/${response.id}`);
      }
    };

    if (!isCancelledRef.current && payment_intent_ref.current) {
      fetchAccountData();
    }

    return () => {
      isCancelledRef.current = true;
    };
  }, [authToken, navigate]);

  return (
    <div className="flex flex-col justify-between h-screen w-full overflow-hidden">
      <HeaderComponent />
      <div className="flex flex-1 items-center justify-center">
        {loading && <RotatingLinesLoader />}
      </div>
      <FooterComponent />
    </div>
  );
};

export default PaymentCallbackPage;
