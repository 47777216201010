import { FooterLogo } from '../../assets';
import { HiOutlineLogout } from 'react-icons/hi';
import { FaRegUser } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import useAuthStore from '../../store/AuthenticationStore';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../../constants/navigators';
import { menuItems } from '../../constants/menuItems';
import { signOut } from '@aws-amplify/auth';
import { UserDetails } from '../../types/UserDetails.type';

interface HeaderProps {
  showSignIn?: boolean;
  showActionButton?: boolean;
}

const HeaderComponent = (props: HeaderProps) => {
  const { showSignIn, showActionButton = true } = props;

  const [showMenus, setShowMenus] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { logOut, userDetails } = useAuthStore() as {
    logOut: () => void;
    userDetails: UserDetails;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenus(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogOut = () => {
    logOut();
    signOut();
    navigate(navigators.NAVIGATE_TO_LOGIN);
  };

  const handleNavigateToHome = () => {
    if (
      userDetails &&
      userDetails['custom:setup'] !== undefined &&
      userDetails['custom:setup'] !== 'false'
    ) {
      navigate(navigators.NAVIGATE_TO_HOME);
    }
  };

  return (
    <div className="bg-sky-950 px-8 py-4 flex justify-between relative items-center">
      <div className="flex gap-10 items-center">
        <img
          src={FooterLogo}
          alt=""
          className="cursor-pointer"
          onClick={handleNavigateToHome}
        />

        <div className="lg:flex hidden">
          <ul className="flex items-center gap-6 text-white font-bold">
            {menuItems.map(item => (
              <li
                key={item.name}
                className="cursor-pointer hover:text-slate-400 transform transition-colors duration-500"
              >
                <a href={item.link}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex items-center gap-8">
        {showActionButton && (
          <>
            <div className="flex items-center gap-4">
              {showSignIn ? (
                <button
                  type="button"
                  title="user"
                  className="flex items-center md:text-base text-sm whitespace-nowrap gap-6 text-white hover:text-slate-400 
              transform transition-colors duration-500 font-bold"
                  onClick={() => {
                    navigate(navigators.NAVIGATE_TO_LOGIN);
                  }}
                >
                  Sign in
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    title="user"
                    onClick={handleNavigateToHome}
                  >
                    <FaRegUser
                      className="text-white font-bold hover:text-slate-400 transform transition-colors duration-500"
                      size={20}
                    />
                  </button>
                  <button type="button" onClick={handleLogOut} title="log out">
                    <HiOutlineLogout
                      className="text-white hover:text-slate-400 transform transition-colors duration-500 font-bold"
                      size={22}
                    />
                  </button>
                </>
              )}
            </div>

            <div className="lg:hidden flex" ref={menuRef}>
              <button type="button" onClick={() => setShowMenus(!showMenus)}>
                {showMenus ? (
                  <RxCross2
                    className="text-white hover:text-slate-400 transform transition-colors duration-500 font-bold"
                    size={22}
                  />
                ) : (
                  <GiHamburgerMenu
                    className="text-white hover:text-slate-400 transform transition-colors duration-500 font-bold"
                    size={22}
                  />
                )}
              </button>

              <div
                className={`absolute top-20 left-0 bg-sky-950 w-full py-2 ${
                  showMenus ? 'menu-slide-down' : 'menu-slide-up'
                }`}
              >
                <ul className="flex flex-col text-white font-bold">
                  {menuItems.map(item => (
                    <li key={item.name} className="w-full">
                      <button
                        onClick={() => {
                          window.location.href = item.link;
                          setShowMenus(false);
                        }}
                        className="py-4 px-4 hover:bg-sky-900 text-left w-full transform transition-colors duration-500 cursor-pointer"
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
