import { SubmitHandler, useForm } from 'react-hook-form';
import FooterComponent from '../../common/layout/FooterComponent';
import LoginBannerComponent from '../../common/layout/LoginBannerComponent';
import { useSignUpInformationStore } from '../../store/AuthenticationStore';
import { EmailConfirmationFormType } from '../../types/Forms.type';
import { SignUpInformationStoreType } from '../../types/UserDetails.type';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { confirmSignUp, resendSignUpCode } from '@aws-amplify/auth';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';
import { formatTime, scrollToTop } from '../../utils/reUsableFunctions';
import {
  defaultTimerInterval,
  defaultTimerMinutes,
} from '../../constants/DefaultValues';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../../constants/navigators';
import { successMessages } from '../../constants/messages';

const EmailConfirmationPage = () => {
  const { signUpInformation } = useSignUpInformationStore() as {
    signUpInformation: SignUpInformationStoreType;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailConfirmationFormType>();

  const [loading, setLoading] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState(defaultTimerMinutes);
  const [showCodeResendButton, setShowCodeResendButton] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (!showCodeResendButton) {
      const timer = setTimeout(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, defaultTimerInterval);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    if (remainingTime === 0) {
      setShowCodeResendButton(true);
    }
  }, [remainingTime]);

  const onEmailConfirmationFormSubmit: SubmitHandler<
    EmailConfirmationFormType
  > = (userData: EmailConfirmationFormType) => {
    setLoading(true);

    confirmSignUp({
      username: signUpInformation.email,
      confirmationCode: userData.verificationCode,
    })
      .then(() => {
        toast.success(successMessages.SIGN_UP_SUCCESS_MESSAGE);
        navigate(navigators.NAVIGATE_TO_LOGIN);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const resendCode = () => {
    setShowCodeResendButton(false);
    setRemainingTime(defaultTimerMinutes);

    resendSignUpCode({ username: signUpInformation.email })
      .then(() =>
        toast.success(successMessages.VERIFICATION_CODE_RESENT_SUCCESS_MESSAGE)
      )
      .catch(error => toast.error(error.message));
  };

  return (
    <>
      <section className="h-screen w-full flex overflow-hidden">
        <form
          onSubmit={handleSubmit(onEmailConfirmationFormSubmit)}
          className="lg:w-[45%] w-full flex flex-col justify-center px-6 md:px-24 py-12 bg-primary-body h-full"
        >
          <div>
            <h1 className="text-3xl font-extrabold text-gray-900 font-roboto-slab">
              We Emailed You
            </h1>

            <p className="text-gray-600 pt-6">
              Your code is on the way. To log in, enter the code we emailed to
              &nbsp;{signUpInformation.nextStep.codeDeliveryDetails.destination}{' '}
              &nbsp;. It may take a minute to arrive
            </p>

            <div className="py-10">
              <p className="text-sm text-gray-700 font-medium">
                Confirmation Code
              </p>

              <input
                className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                  ${errors.verificationCode ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                {...register('verificationCode', {
                  required: 'Confirmation Code is required',
                })}
                id="confirmationCode"
                type="text"
                placeholder="Enter your code"
              />
              <p className="mt-1 text-sm text-red-500">
                {errors.verificationCode?.message}
              </p>
            </div>

            <button
              type="submit"
              className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 
              hover:shadow-2xl px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm ${loading && 'opacity-70'}`}
              disabled={loading}
            >
              {loading && <RotatingLinesLoader color="white" width="24" />}
              Confirm
            </button>

            <button
              type="button"
              className={`mt-6 relative hover:shadow-2xl px-4 py-3 w-full font-medium rounded-3xl shadow-sm
              ${
                showCodeResendButton
                  ? 'border-[2px] border-primary hover:border-orange-500 text-primary'
                  : 'bg-primary text-white opacity-70'
              }`}
              onClick={resendCode}
              disabled={!showCodeResendButton}
            >
              Resend Code
            </button>

            <span className="text-gray-500 text-sm p-4">
              Resend Code in {formatTime(remainingTime)}
            </span>
          </div>
        </form>
        <div className=" w-[55%] relative lg:flex hidden">
          <LoginBannerComponent />
        </div>
      </section>

      <section>
        <FooterComponent />
      </section>
    </>
  );
};

export default EmailConfirmationPage;
