/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from 'aws-amplify/api';
import { errorMessages } from '../../constants/messages';

async function createInstanceProvisioningJob(authToken: string): Promise<any> {
  try {
    const restOperation = post({
      apiName: 'InstanceProvisioningApi',
      path: '/instance-provisioning-jobs',
      options: {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

    throw errorMessage;
  }
}

async function getInstanceProvisioningJob(
  id: any,
  authToken: string
): Promise<any> {
  try {
    const restOperation = get({
      apiName: 'InstanceProvisioningApi',
      path: `/instance-provisioning-jobs/${id}`,
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

    throw errorMessage;
  }
}

async function createInstanceProvisioningJobWithPaymentIntentId(
  paymentIntentId: any,
  authToken: string
): Promise<any> {
  try {
    const restOperation = post({
      apiName: 'InstanceProvisioningApi',
      path: `/instance-provisioning-jobs`,
      options: {
        headers: {
          Authorization: authToken,
          'content-type': 'application/json',
        },
        body: {
          paymentIntentId,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

    throw errorMessage;
  }
}

export {
  createInstanceProvisioningJob,
  getInstanceProvisioningJob,
  createInstanceProvisioningJobWithPaymentIntentId,
};
