import { RotatingLines } from 'react-loader-spinner';

interface RotatingLinesLoaderProps {
  width?: string;
  color?: string;
}

const RotatingLinesLoader = (props: RotatingLinesLoaderProps) => {
  const { width, color } = props;

  return (
    <RotatingLines
      visible={true}
      width={width ?? '96'}
      strokeColor={color ?? '#f37b0b'}
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
    />
  );
};

export default RotatingLinesLoader;
