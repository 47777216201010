import { useNavigate } from 'react-router-dom';
import FooterComponent from '../../common/layout/FooterComponent';
import LoginBannerComponent from '../../common/layout/LoginBannerComponent';
import { navigators } from '../../constants/navigators';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ForgotPasswordFormType } from '../../types/Forms.type';
import { resetPassword, ResetPasswordOutput } from '@aws-amplify/auth';
import { useState } from 'react';
import { toast } from 'react-toastify';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';
import { useForgotPasswordInformationStore } from '../../store/AuthenticationStore';
import { ForgotPasswordStoreType } from '../../types/UserDetails.type';

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormType>();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const { setForgotPassword } = useForgotPasswordInformationStore() as {
    setForgotPassword: (
      _newForgotPasswordInformation:
        | ForgotPasswordStoreType
        | ResetPasswordOutput
    ) => void;
  };

  const onForgotPasswordSubmit: SubmitHandler<ForgotPasswordFormType> = (
    userData: ForgotPasswordFormType
  ) => {
    setLoading(true);

    resetPassword({ username: userData.email })
      .then(response => {
        Object.assign(response, { email: userData.email });
        setForgotPassword(response);
        navigate(`${navigators.NAVIGATE_TO_RESET_PASSWORD}`);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <section className="h-screen w-full flex overflow-hidden">
        <form
          onSubmit={handleSubmit(onForgotPasswordSubmit)}
          className="lg:w-[45%] w-full flex flex-col justify-center px-6 md:px-24 py-12 bg-primary-body h-full"
        >
          <div>
            <h1 className="text-3xl font-extrabold text-gray-900 font-roboto-slab">
              Reset Password
            </h1>

            <div className="py-10">
              <p className="text-sm text-gray-700 font-medium">Email</p>

              <input
                className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                    ${errors.email ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                id="email"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Please enter a valid email address',
                  },
                })}
                placeholder="Enter your email"
              />
              {errors.email && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>

            <button
              type="submit"
              className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 
              hover:shadow-2xl px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm ${loading && 'opacity-70'}`}
              disabled={loading}
            >
              {loading && <RotatingLinesLoader color="white" width="24" />}
              Send code
            </button>

            <button
              type="button"
              className="mt-6 relative border-[2px] border-primary hover:border-orange-500 hover:shadow-2xl px-4 py-3 w-full
           text-primary font-medium rounded-3xl shadow-sm"
              onClick={() => {
                navigate(navigators.NAVIGATE_TO_LOGIN);
              }}
            >
              Login
            </button>
          </div>
        </form>
        <div className=" w-[55%] relative lg:flex hidden">
          <LoginBannerComponent />
        </div>
      </section>

      <section>
        <FooterComponent />
      </section>
    </>
  );
};

export default ForgotPasswordPage;
