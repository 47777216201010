import { create } from 'zustand';

interface LoaderStore {
  isLoading: boolean;
  toggleGlobalLoader: (_state: boolean) => void;
}

export const useLoaderStore = create<LoaderStore>(set => ({
  isLoading: false,
  toggleGlobalLoader: (state: boolean) => set({ isLoading: state }),
}));
