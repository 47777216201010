import { create } from 'zustand';
import {
  ForgotPasswordStoreType,
  SignUpInformationStoreType,
  UserDetails,
} from '../types/UserDetails.type';
import { LocalStorage } from '../constants/storage';

interface UserDetailsConnectionStore {
  userDetails: UserDetails | null;
  setUserDetails: (_newUserDetails: UserDetails) => void;
  logOut: () => void;
  authToken: string | null;
}

const getUserDetails = (): UserDetails | null => {
  const storedUser = localStorage.getItem(LocalStorage.USER_DETAILS);
  return storedUser ? (JSON.parse(storedUser) as UserDetails) : null;
};

const getAuthToken = (userDetails: UserDetails | null): string | null => {
  if (!userDetails) return null;
  const storedAuthToken = localStorage.getItem(
    `${LocalStorage.COGNITO_IDENTITY_SERVICE_PROVIDER}.${userDetails?.clientId}.${userDetails?.accessId}.${LocalStorage.ID_TOKEN}`
  );
  return storedAuthToken;
};

const useAuthStore = create<UserDetailsConnectionStore>(set => {
  const initialUserDetails = getUserDetails();
  const initialAuthToken = getAuthToken(initialUserDetails);

  return {
    userDetails: initialUserDetails,
    authToken: initialAuthToken,
    setUserDetails: (newUserDetails: UserDetails): void => {
      set({ userDetails: newUserDetails });
      localStorage.setItem(
        LocalStorage.USER_DETAILS,
        JSON.stringify(newUserDetails)
      );
      set({ authToken: getAuthToken(newUserDetails) });
    },
    logOut: () => {
      localStorage.removeItem(LocalStorage.USER_DETAILS);
      set({ userDetails: null, authToken: null });
    },
  };
});

interface ForgotPasswordStoreProps {
  forgotPasswordInformation: ForgotPasswordStoreType;
  setForgotPassword: (
    _newForgotPasswordInformation: ForgotPasswordStoreType
  ) => void;
}

export const useForgotPasswordInformationStore =
  create<ForgotPasswordStoreProps>(set => ({
    forgotPasswordInformation: {
      email: '',
      isPasswordReset: false,
      nextStep: {
        resetPasswordStep: '',
        codeDeliveryDetails: {
          deliveryMedium: '',
          destination: '',
          attributeName: '',
        },
      },
    },
    setForgotPassword: (
      newForgotPasswordInformation: ForgotPasswordStoreType
    ): void => {
      set({ forgotPasswordInformation: newForgotPasswordInformation });
    },
  }));

interface SignUpInformationStoreProps {
  signUpInformation: SignUpInformationStoreType;
  setSignUpInformation: (
    _newSignUpInformation: SignUpInformationStoreType
  ) => void;
}

export const useSignUpInformationStore = create<SignUpInformationStoreProps>(
  set => ({
    signUpInformation: {
      email: '',
      isSignUpComplete: false,
      nextStep: {
        signUpStep: '',
        codeDeliveryDetails: {
          deliveryMedium: '',
          destination: '',
          attributeName: '',
        },
      },
    },
    setSignUpInformation: (
      newSignUpInformation: SignUpInformationStoreType
    ): void => {
      set({ signUpInformation: newSignUpInformation });
    },
  })
);

export default useAuthStore;
