/* eslint-disable react-refresh/only-export-components */
import { type ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/AuthenticationStore';
import { navigators } from '../constants/navigators';

interface AuthenticatedGuardProps {
  children: ReactNode;
}

export function isAuthenticated(authToken: string | null): boolean {
  if (authToken === null) {
    return false;
  } else {
    return true;
  }
}

export function AuthenticatedGuard({ children }: AuthenticatedGuardProps) {
  const { authToken } = useAuthStore() as {
    authToken: string | null;
  };

  const navigate = useNavigate();
  const userAuthenticated = isAuthenticated(authToken);

  useEffect(() => {
    if (!userAuthenticated) {
      navigate(navigators.NAVIGATE_TO_LOGIN);
    }
  }, [userAuthenticated, navigate]);

  return children;
}
