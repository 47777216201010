import React, { useEffect } from 'react';
import useCustomerStore from '../../store/CustomerStore';
import { ResponseCustomerPortalSessionDetails } from '../../types/PaymentInformation.type';
import { useLoaderStore } from '../../store/LoaderStore';
import { UserDetails } from '../../types/UserDetails.type';
import useAuthStore from '../../store/AuthenticationStore';
import { activePlansInformationTab } from '../../constants/DefaultValues';

interface PaymentInformationComponentProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const PaymentInformationComponent = (
  props: PaymentInformationComponentProps
) => {
  const { setActiveTab } = props;

  const { getCustomerPortalSession, customerPortalSessionDetails } =
    useCustomerStore() as {
      getCustomerPortalSession: (
        _customerId: string,
        _workFlowRedirectURL: string
      ) => Promise<void>;
      customerPortalSessionDetails: ResponseCustomerPortalSessionDetails;
    };

  const { toggleGlobalLoader } = useLoaderStore() as {
    toggleGlobalLoader: (_state: boolean) => void;
  };

  const { userDetails } = useAuthStore() as { userDetails: UserDetails };

  const workFlowRedirectURL = import.meta.env.VITE_WORKFLOW_REDIRECTION_URL;

  useEffect(() => {
    const fetchCustomerPortalSession = async (psCustomerId: string) => {
      toggleGlobalLoader(true);
      await getCustomerPortalSession(psCustomerId, workFlowRedirectURL);
      toggleGlobalLoader(false);
    };

    if (
      userDetails['custom:ps_subscription_id'] &&
      userDetails['custom:ps_customer_id']
    ) {
      fetchCustomerPortalSession(userDetails['custom:ps_customer_id']);
    }
  }, [
    getCustomerPortalSession,
    toggleGlobalLoader,
    userDetails,
    workFlowRedirectURL,
  ]);

  useEffect(() => {
    if (customerPortalSessionDetails?.data?.url) {
      window.location.href = customerPortalSessionDetails.data.url;
    }
  }, [customerPortalSessionDetails]);

  return (
    <div>
      <p className="tracking-[0.6px]">Payments</p>

      {(!userDetails['custom:ps_subscription_id'] ||
        !userDetails['custom:ps_customer_id']) && (
        <div className="mt-10 w-full text-center">
          <p className="text-lg font-semibold">
            No payment method found. Add your preferred payment method to keep
            things rolling smoothly.
          </p>
          <p className="mt-4 text-gray-600">
            Don't miss out—make your first payment and try again!
          </p>

          <button
            type="button"
            className={`bg-primary border-[1px] border-primary text-white px-4 py-2 rounded-lg font-medium shadow-md hover:bg-orange-600 
            hover:border-orange-600 hover:shadow-2xl transition-all duration-500 mt-8`}
            onClick={() => {
              setActiveTab(activePlansInformationTab);
            }}
          >
            Select New Plan
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentInformationComponent;
