import { get, post } from 'aws-amplify/api';
import { errorMessages } from '../../constants/messages';

async function createSubdomain(name: string, authToken: string) {
  try {
    const restOperation = post({
      apiName: 'InstanceProvisioningApi',
      path: '/subdomains',
      options: {
        headers: {
          Authorization: authToken,
        },
        body: {
          name,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;
    throw errorMessage;
  }
}

async function validateSubdomain(name: string, authToken: string) {
  try {
    const restOperation = post({
      apiName: 'InstanceProvisioningApi',
      path: '/subdomains?validate=true',
      options: {
        headers: {
          Authorization: authToken,
        },
        body: {
          name,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;
    throw errorMessage;
  }
}

async function getSubdomains(authToken: string) {
  try {
    const restOperation = get({
      apiName: 'InstanceProvisioningApi',
      path: '/subdomains',
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;
    throw errorMessage;
  }
}

export { createSubdomain, getSubdomains, validateSubdomain };
