import { create } from 'zustand';
import {
  CustomerPortalSessionDetailsType,
  PaymentDetailsResponseType,
} from '../types/PaymentInformation.type';
import {
  fetchCustomerPortalSession,
  fetchPaymentDetails,
} from '../api/customerApi/Customer.Api';

const initialState = {
  paymentDetails: {} as PaymentDetailsResponseType,
  error: null,
  isLoading: false,
  customerPortalSessionDetails: {} as CustomerPortalSessionDetailsType,
};

const useCustomerStore = create(set => ({
  ...initialState,

  getPaymentDetails: async (customerId: string): Promise<void> => {
    set({ isLoading: true, error: null });
    try {
      const paymentDetails = await fetchPaymentDetails(customerId);
      set({
        paymentDetails: paymentDetails.data,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({ isLoading: false, error });
    }
  },

  getCustomerPortalSession: async (
    customerId: string,
    workFlowRedirectURL: string
  ): Promise<void> => {
    set({ isLoading: true, error: null });
    try {
      const customerPortalSessionDetailsResponse =
        await fetchCustomerPortalSession(customerId, workFlowRedirectURL);
      set({
        customerPortalSessionDetails: customerPortalSessionDetailsResponse.data,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({ isLoading: false, error });
    }
  },
}));

export default useCustomerStore;
