export const navigators = {
  NAVIGATE_TO_HOME: '/',
  NAVIGATE_TO_LOGIN: '/login',
  NAVIGATE_TO_SIGNUP: '/sign-up',
  NAVIGATE_TO_EMAIL_CONFIRMATION: '/email-confirmation',
  NAVIGATE_TO_FORGOT_PASSWORD: '/forgot-password',
  NAVIGATE_TO_RESET_PASSWORD: '/reset-password',
  NAVIGATE_TO_ACCOUNT_SETUP: '/account-setup',
  NAVIGATE_BACK: -1,
};
