import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { SignUpFormType } from '../../types/Forms.type';
import {
  passwordRegexValue,
  passwordValidationErrorMessage,
} from '../../constants/DefaultValues';

interface ProfileInformationForm {
  register: UseFormRegister<SignUpFormType>;
  errors: FieldErrors<SignUpFormType>;
  password: string;
  image: string | null;
  handleImageChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProfileInformationForm = (props: ProfileInformationForm) => {
  const { register, errors, password } = props;

  return (
    <>
      <h1 className="font-medium text-lg text-gray-900">Profile</h1>
      <p className="text-gray-500 mb-8">
        Your user name will be shared publicly so be careful what you share.
      </p>

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-y-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Username<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <input
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
                  ${errors.username ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
            id="userName"
            type="text"
            autoFocus
            {...register('username', { required: 'Username is required' })}
          />
          <p className="mt-1 text-sm">
            {errors.username && (
              <span className="text-red-500">{errors.username.message}</span>
            )}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-6 py-6 border-b-[1px] border-gray-200">
        <div className="flex lg:flex-row flex-col lg:gap-10 gap-2">
          <p className="lg:w-[30%] w-full text-gray-700">
            Password<span className="text-red-500">*</span>
          </p>

          <div className="w-full flex flex-col lg:mx-20">
            <input
              className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
  ${errors.password ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
              id="password"
              type="password"
              {...register('password', {
                required: 'Password is required',
                pattern: {
                  value: passwordRegexValue,
                  message: passwordValidationErrorMessage,
                },
              })}
            />
            <p className="mt-1 text-sm">
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </p>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col lg:gap-10 gap-2">
          <p className="lg:w-[30%] w-full text-gray-700">
            Password confirmation<span className="text-red-500">*</span>
          </p>

          <div className="w-full flex flex-col lg:mx-20">
            <input
              className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
  ${errors.confirmPassword ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
              id="passwordConfirmation"
              type="password"
              {...register('confirmPassword', {
                required: 'Password Confirmation is required',
                validate: value =>
                  value === password || 'Passwords do not match',
              })}
            />
            {errors.confirmPassword && (
              <p className="text-sm text-red-500 mt-1">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        </div>
      </div>

      {/*
      <div className="flex lg:flex-row flex-col lg:gap-10 gap-6 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">Photo</p>

        <div className="w-full flex items-center gap-5 lg:mx-20">
          <img
            src={image ?? Avatar}
            alt=""
            className="size-16 rounded-full object-contain shadow-md border cursor-pointer"
          />

          <div>
            <label
              htmlFor="image-upload"
              className="flex flex-col items-center justify-center h-fit border-[1px] border-gray-300 rounded-lg 
                  cursor-pointer bg-white hover:bg-gray-100"
            >
              <div className="flex flex-col items-center justify-center px-4 py-2">
                <span
                  id="image-preview-text"
                  className="font-medium text-gray-700"
                >
                  Change
                </span>
              </div>
              <input
                id="image-upload"
                type="file"
                className="hidden"
                accept="image/*"
                {...register('image')}
                onChange={event => {
                  handleImageChange(event);
                }}
              />
            </label>
          </div>
        </div>
      </div>*/}
    </>
  );
};

export default ProfileInformationForm;
