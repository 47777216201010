/* eslint-disable @typescript-eslint/no-explicit-any */
import { MdDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { formatDateAsPerLocalType } from '../../../utils/DateTime.functions';

interface TableComponentProps {
  tableHeader: { header: string; field: string }[];
  tableData: { [key: string]: any }[];
  showEditOption: boolean;
  showDeleteOption: boolean;
  showRedirectButton: boolean;
  onAccessInstanceClick: (_instanceValues: any) => void;
}

const getStatusClassName = (status: string) => {
  switch (status.toLowerCase()) {
    case 'available':
      return 'bg-green-600';
    case 'inactive':
      return 'bg-red-600';
    case 'inprogress':
      return 'bg-blue-600';
    default:
      return 'bg-gray-600';
  }
};

const renderCellContent = (
  field: string,
  value: any,
  rowData: { [key: string]: any },
  showEditOption: boolean,
  showDeleteOption: boolean,
  showRedirectButton: boolean,
  onAccessInstanceClick: (__instanceValues: any) => void
) => {
  if (field === '') {
    return (
      <div className="flex gap-2 items-center justify-center">
        {showRedirectButton && (
          <button
            onClick={() => {
              onAccessInstanceClick(rowData);
            }}
            type="button"
            className={`border-[1px] border-primary text-sm text-primary px-2 py-[6px] rounded-lg font-medium 
              shadow-md hover:border-orange-600 hover:shadow-2xl transition-all duration-500`}
          >
            Access Instance
          </button>
        )}

        {showEditOption && (
          <MdOutlineEdit
            className="text-primary rounded-full hover:scale-110 cursor-pointer"
            size={24}
          />
        )}

        {showDeleteOption && (
          <MdDeleteOutline
            className="text-primary rounded-full hover:scale-110 cursor-pointer"
            size={24}
          />
        )}
      </div>
    );
  }

  if (field === 'state') {
    return (
      <div className="w-full flex justify-center">
        <div
          className={`${getStatusClassName(value)} text-xs text-white w-fit px-4 rounded-full shadow-md py-1 text-center capitalize`}
        >
          {value}
        </div>
      </div>
    );
  }

  if (field === 'createdAt') {
    return (
      <div className="flex gap-2 items-center">
        <p>{formatDateAsPerLocalType(value, 'YYYY-MM-DD HH:mm:ss')}</p>
      </div>
    );
  }

  return value;
};

const TableComponent = (props: TableComponentProps) => {
  const {
    tableHeader,
    tableData,
    showEditOption,
    showDeleteOption,
    showRedirectButton,
    onAccessInstanceClick,
  } = props;

  return (
    <div className="border-[1px] border-gray-200 table-shadow rounded-md w-full">
      <div className="overflow-x-auto">
        <table className="table-auto min-w-full md:text-base text-sm">
          <thead>
            <tr>
              {tableHeader.map((column, index) => (
                <td
                  key={column.header}
                  className={`uppercase ${column.header === 'status' || column.header === 'action' ? 'text-center' : 'text-start'}
                     ${index === 0 ? 'rounded-tl-md' : ''} ${
                       index === tableHeader.length - 1 ? 'rounded-tr-md' : ''
                     } px-7 py-3 tracking-[0.05em] text-sm text-center font-medium text-gray-500 bg-gray-50 border-b-[1px] border-gray-200`}
                >
                  {column.header}
                </td>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={`${rowIndex + 1}`}>
                {tableHeader.map((column, colIndex) => (
                  <td
                    key={`${colIndex + 1}`}
                    className={`text-start text-sm px-7 py-4 text-gray-500 min-w-fit max-w-52 whitespace-nowrap truncate ${
                      rowIndex < tableData.length - 1
                        ? 'border-b-[1px] border-gray-200'
                        : ''
                    }`}
                    title={`${row[column.field] ? row[column.field] : ''}`}
                  >
                    {renderCellContent(
                      column.field,
                      row[column.field],
                      row,
                      showEditOption,
                      showDeleteOption,
                      showRedirectButton,
                      onAccessInstanceClick
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
