import { statusCodes } from '../constants/statusCodes';
import { ErrorResponseData } from '../types/api.type';

export const getErrorMessage = (
  status: number,
  errorData?: ErrorResponseData
): string => {
  switch (status) {
    case statusCodes.HTTP_STATUS_BAD_REQUEST:
      return 'Bad Request: ' + (errorData?.message ?? 'Invalid request.');
    case statusCodes.HTTP_STATUS_UNAUTHORIZED:
      return 'Unauthorized: ' + (errorData?.message ?? 'Please login.');
    case statusCodes.HTTP_STATUS_FORBIDDEN:
      return 'Forbidden: ' + (errorData?.message ?? 'Access denied.');
    case statusCodes.HTTP_STATUS_NOT_FOUND:
      return 'Not Found: ' + (errorData?.message ?? 'Resource not found.');
    case statusCodes.HTTP_STATUS_INTERNAL_SERVER_ERROR:
      return (
        'Internal Server Error: ' +
        (errorData?.message ?? 'Something went wrong.')
      );
    default:
      return 'An unexpected error occurred.';
  }
};
