import { useEffect, useState } from 'react';
import useAuthStore from '../../store/AuthenticationStore';
import { UserDetails } from '../../types/UserDetails.type';
import ChangePasswordModal from './ChangePasswordModal';
import { MdOutlineEdit } from 'react-icons/md';
import EditProfileModal from './EditProfileModal';

const ProfileInformationComponent = () => {
  const { userDetails } = useAuthStore() as { userDetails: UserDetails };

  const [isProviderGoogle, setIsProviderGoogle] = useState<boolean>(false);

  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);

  const [showEditProfileModal, setShowEditProfileModal] =
    useState<boolean>(false);

  useEffect(() => {
    const identities = userDetails?.identities;
    if (identities) {
      const parsedUserIdentity = JSON.parse(identities);
      setIsProviderGoogle(parsedUserIdentity[0]?.providerName === 'Google');
    }
  }, [userDetails]);

  if (!userDetails) {
    return;
  }

  return (
    <div>
      <p className="tracking-[0.6px]">Profile</p>

      <div className=" border-[1px] border-gray-200 table-shadow rounded-md mt-5 lg:min-w-[60%] lg:max-w-fit w-full">
        <div className="flex md:flex-row flex-col justify-between md:items-center items-start md:gap-10 gap-4 px-6 py-4">
          <div className="flex-1">
            <h1 className=" font-medium">Company</h1>
          </div>
          <div className="flex-1">
            <h1 className="text-gray-500  font-normal capitalize">
              {userDetails?.['custom:organization_name'] ?? '-'}
            </h1>
          </div>
        </div>

        <div
          className="flex md:flex-row flex-col justify-between md:items-center items-start 
        md:gap-10 gap-4 px-6 py-4 border-t-[1px] border-gray-200"
        >
          <div className="flex-1">
            <h1 className=" font-medium">Name</h1>
          </div>
          <div className="flex-1">
            <h1 className="text-gray-500 font-normal capitalize">
              {userDetails.given_name ?? '-'}
            </h1>
          </div>
        </div>

        <div
          className="flex md:flex-row flex-col justify-between md:items-center items-start 
        md:gap-10 gap-4 px-6 py-4 border-t-[1px] border-gray-200"
        >
          <div className="flex-1">
            <h1 className=" font-medium">Email</h1>
          </div>
          <div className="flex-1">
            <h1 className="text-gray-500 font-normal">
              {userDetails.email ?? '-'}
            </h1>
          </div>
        </div>

        <div
          className="flex md:flex-row flex-col justify-between md:items-center items-start 
        md:gap-10 gap-4 px-6 py-4 border-t-[1px] border-gray-200"
        >
          <div className="flex-1">
            <h1 className=" font-medium">Customer ID</h1>
          </div>
          <div className="flex-1">
            <h1 className="text-gray-500 font-normal">
              {userDetails['custom:ps_customer_id'] ?? '-'}
            </h1>
          </div>
        </div>

        {!isProviderGoogle && (
          <div
            className="flex md:flex-row flex-col justify-between md:items-center items-start
                 md:gap-10 gap-4 px-6 py-4 border-t-[1px] border-gray-200"
          >
            <div className="flex-1">
              <h1 className="font-medium">Password</h1>
            </div>
            <div className="flex-1 w-full flex justify-between gap-2 items-center">
              <h1 className="text-gray-500 font-normal">
                {userDetails.password ?? '-'}
              </h1>
              <button
                type="button"
                className="focus:outline-none font-light text-[#4F46E5]"
                onClick={() => setShowChangePasswordModal(true)}
              >
                Change
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="flex lg:min-w-[60%] lg:max-w-fit w-full justify-end items-end mt-6">
        <button
          type="button"
          className="border-primary border-[2px] text-primary px-4 py-2 rounded-lg font-medium shadow-md hover:bg-border-600 
                hover:shadow-2xl transition-all duration-500 flex items-center justify-center gap-2"
          onClick={() => {
            setShowEditProfileModal(true);
          }}
        >
          <MdOutlineEdit size={20} />
          <span>Edit Profile</span>
        </button>
      </div>

      {showChangePasswordModal && (
        <ChangePasswordModal
          visible={showChangePasswordModal}
          setVisible={setShowChangePasswordModal}
        />
      )}

      {showEditProfileModal && (
        <EditProfileModal
          visible={showEditProfileModal}
          setVisible={setShowEditProfileModal}
        />
      )}
    </div>
  );
};

export default ProfileInformationComponent;
