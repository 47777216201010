import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ErrorResponseData } from '../types/api.type';
import { getErrorMessage } from './errorHandlers';
import { errorMessages } from '../constants/messages';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PAYMENT_API_URL,
  headers: {
    'Content-Type': 'application/json',
    accept: '*/*',
    apiKey: import.meta.env.VITE_PAYMENT_API_KEY,
  },
});

axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    const err = error instanceof Error ? error : new Error('Request error');
    toast.error(errorMessages.API_REQUEST_ERROR);
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;
      const errorData = data as ErrorResponseData;

      const errorMessage = getErrorMessage(status, errorData);
      toast.error(errorMessage);
    } else if (error.request) {
      toast.info(errorMessages.NETWORK_ERROR);
    } else {
      toast.error('Error: ' + error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
