/* eslint-disable camelcase */
import { SubmitHandler, useForm } from 'react-hook-form';
import GenericLayout from '../../common/layout/GenericLayout';
import { SignUpFormType } from '../../types/Forms.type';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../../constants/navigators';
import { scrollToTop } from '../../utils/reUsableFunctions';
import { errorMessages } from '../../constants/messages';
import PersonalInformationForm from '../../components/signUpComponents/PersonalInformationForm';
import ProfileInformationForm from '../../components/signUpComponents/ProfileInformationForm';
import { signUp, SignUpOutput } from '@aws-amplify/auth';
import { useSignUpInformationStore } from '../../store/AuthenticationStore';
import { SignUpInformationStoreType } from '../../types/UserDetails.type';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';

const SignUpPage = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm<SignUpFormType>();

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const navigate = useNavigate();

  const [image, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { setSignUpInformation } = useSignUpInformationStore() as {
    setSignUpInformation: (
      _newSignUpInformation: SignUpInformationStoreType | SignUpOutput
    ) => void;
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (confirmPassword) trigger('confirmPassword');
    if (password) trigger('password');
  }, [password, confirmPassword, trigger]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        toast.error(errorMessages.ONLY_IMAGE_FILES_ERROR);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSignUpSubmit: SubmitHandler<SignUpFormType> = (
    userData: SignUpFormType
  ) => {
    setLoading(true);

    signUp({
      username: userData.email,
      password: userData.password,
      options: {
        userAttributes: {
          email: userData.email,
          given_name: userData.firstName,
          phone_number: `+${userData.phone}`,
          family_name: userData.lastName,
          'custom:subdomain': '',
          'custom:organization_name': '',
        },
      },
    })
      .then(response => {
        Object.assign(response, { email: userData.email });
        setSignUpInformation(response);
        navigate(`${navigators.NAVIGATE_TO_EMAIL_CONFIRMATION}`);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <GenericLayout showSignIn={true}>
      <div className="bg-white py-16 xl:w-[1073px] lg:w-[900px] w-full mx-auto">
        <form
          className="md:px-6 px-4 py-4"
          onSubmit={handleSubmit(onSignUpSubmit)}
        >
          <ProfileInformationForm
            register={register}
            errors={errors}
            password={password}
            image={image}
            handleImageChange={handleImageChange}
          />

          <PersonalInformationForm
            setValue={setValue}
            register={register}
            trigger={trigger}
            errors={errors}
          />

          <div className="flex items-center justify-center gap-6 mt-10">
            <button
              type="button"
              className="px-4 py-2 rounded-full hover:border-orange-400 hover:scale-105 shadow-md border-[1px] 
              border-primary text-sm font-medium transition-all duration-200"
              onClick={() => {
                navigate(navigators.NAVIGATE_BACK);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 rounded-full flex items-center gap-2 hover:bg-orange-500 hover:scale-105 shadow-md bg-primary 
               text-white border-[1px] border-primary text-sm font-medium transition-all duration-200
                ${loading || !isValid ? 'opacity-60 cursor-not-allowed' : 'opacity-100'}`}
              disabled={loading || !isValid}
            >
              {loading && <RotatingLinesLoader color="white" width="20" />}
              Sign up
            </button>
          </div>
        </form>
      </div>
    </GenericLayout>
  );
};

export default SignUpPage;
