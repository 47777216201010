import HeaderComponent from '../common/layout/HeaderComponent';
import FooterComponent from '../common/layout/FooterComponent';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SignUpFormType } from '../types/Forms.type';
import RotatingLinesLoader from '../common/components/spinners/RotatingLinesLoader';
import { useState, useEffect, useRef } from 'react';
import { validateSubdomain } from '../api/subDomains';
import useAuthStore from '../store/AuthenticationStore';
import {
  defaultTimerForSubDomainChecker,
  subDomainValidityMinimumLength,
} from '../constants/DefaultValues';
import {
  FetchUserAttributesOutput,
  updateUserAttributes,
} from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../constants/navigators';
import { toast } from 'react-toastify';
import { errorMessages, successMessages } from '../constants/messages';
import { UserDetails } from '../types/UserDetails.type';
import debounce from 'lodash.debounce';

const AccountSetUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger,
    setValue,
  } = useForm<SignUpFormType>();

  const subDomain = watch('subDomain');

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [isSubDomainLoading, setIsSubDomainLoading] = useState<boolean>(false);
  const [isSubDomainAvailable, setIsSubDomainAvailable] =
    useState<boolean>(false);

  const { setUserDetails, authToken, userDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    authToken: string | null;
    userDetails: UserDetails;
  };

  const validateSubDomainAvailability = async (subDomain: string) => {
    if (subDomain.length > subDomainValidityMinimumLength) {
      setIsSubDomainLoading(true);
      try {
        const valid = await validateSubdomain(subDomain, authToken ?? '');
        setIsSubDomainAvailable(valid as boolean);
      } catch (error) {
        if ((error as Error)?.message === 'subdomain is not available') {
          setIsSubDomainAvailable(false);
        }
      } finally {
        setIsSubDomainLoading(false);
      }
    }
  };

  const debouncedCheckSubDomain = useRef(
    debounce((subDomain: string) => {
      validateSubDomainAvailability(subDomain);
    }, defaultTimerForSubDomainChecker)
  ).current;

  useEffect(() => {
    if (subDomain && subDomain.length > subDomainValidityMinimumLength) {
      debouncedCheckSubDomain(subDomain);
    }
    return () => {
      debouncedCheckSubDomain.cancel();
    };
  }, [subDomain, debouncedCheckSubDomain]);

  const onAccountSetUpSubmit: SubmitHandler<SignUpFormType> = async (
    accountData: SignUpFormType
  ) => {
    setLoading(true);

    const { subDomain, company } = accountData;
    const userAttributes = {
      'custom:subdomain': subDomain,
      'custom:organization_name': company,
      'custom:setup': 'true',
    };

    try {
      await updateUserAttributes({
        userAttributes,
      });

      setUserDetails({
        ...userDetails,
        ...userAttributes,
      });

      toast.success(successMessages.ACCOUNT_SETUP_SUCCESS_MESSAGE);

      navigate(navigators.NAVIGATE_TO_HOME);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between overflow-hidden">
      <HeaderComponent showActionButton={false} />

      <div className="flex-1 bg-primary-body">
        <div className="flex flex-col items-center justify-center h-full md:mx-auto mx-8">
          <form
            className="px-10 py-5 bg-white rounded-md shadow-md"
            onSubmit={handleSubmit(onAccountSetUpSubmit)}
          >
            <h1 className="text-3xl font-extrabold text-center text-gray-900 font-roboto-slab">
              Account Setup
            </h1>

            <div className="space-y-6 mt-8">
              <div>
                <p className="text-sm text-gray-700 font-medium mb-1">
                  Company<span className="text-red-500">*</span>
                </p>
                <input
                  className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                  ${errors.company ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                  id="company"
                  type="text"
                  {...register('company', { required: 'Company is required' })}
                />
                {errors.company && (
                  <p className="text-sm text-red-500 mt-1">
                    {errors.company.message}
                  </p>
                )}
              </div>

              <div>
                <p className="text-sm text-gray-700 font-medium mb-1">
                  Sub Domain<span className="text-red-500">*</span>
                </p>
                <div className="flex shadow-sm">
                  <input
                    type="text"
                    className={`bg-white px-4 py-2 rounded-r-none border-[1px] w-full rounded-md outline-none
                      ${errors.subDomain ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                    id="subDomain"
                    maxLength={15}
                    {...register('subDomain', {
                      required: 'Sub Domain is required',
                      pattern: {
                        value: /^[a-z0-9-]+$/,
                        message:
                          'Only lowercase letters, numbers, and hyphens are allowed',
                      },
                    })}
                    onChange={e => {
                      const value = e.target.value.toLowerCase();
                      setValue('subDomain', value);
                      trigger('subDomain');
                      // debouncedCheckSubDomain(value);
                    }}
                    // readOnly={isSubDomainLoading}
                  />

                  <span className="px-4 py-2 rounded-r border-[1px] border-l-0 border-gray-300 whitespace-nowrap bg-gray-200">
                    .node-ready.com
                  </span>
                </div>

                {errors.subDomain && (
                  <p className="text-sm text-red-500 mt-1 mb-2">
                    {errors.subDomain.message}
                  </p>
                )}

                {!errors.subDomain && (
                  <>
                    {isSubDomainLoading ? (
                      <div className="flex gap-2 items-center mt-2">
                        <RotatingLinesLoader width="28" />
                        <span className="text-sm text-gray-500">
                          Looking for the sub domain availability
                        </span>
                      </div>
                    ) : (
                      <>
                        {isSubDomainAvailable ? (
                          <div className="flex items-center gap-2 mt-2">
                            <div className="animation-ctn">
                              <div className="icon icon--order-success svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28px"
                                  height="28px"
                                  viewBox="0 0 154 154"
                                >
                                  <g
                                    fill="none"
                                    stroke="#22AE73"
                                    strokeWidth="2"
                                  >
                                    <circle
                                      cx="77"
                                      cy="77"
                                      r="72"
                                      style={{
                                        strokeDasharray: '480px, 480px',
                                        strokeDashoffset: '960px',
                                      }}
                                    ></circle>
                                    <circle
                                      id="colored"
                                      fill="#22AE73"
                                      cx="77"
                                      cy="77"
                                      r="72"
                                      style={{
                                        strokeDasharray: '480px, 480px',
                                        strokeDashoffset: '960px',
                                      }}
                                    ></circle>
                                    <polyline
                                      className="st0"
                                      stroke="#fff"
                                      strokeWidth="15"
                                      points="43.5,77.8 63.7,97.9 112.2,49.4 "
                                      style={{
                                        strokeDasharray: '100px, 100px',
                                        strokeDashoffset: '200px',
                                        strokeLinejoin: 'round',
                                        strokeLinecap: 'round',
                                      }}
                                    />
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <span className="text-sm font-medium text-green-500">
                              Sub Domain is available
                            </span>
                          </div>
                        ) : (
                          <>
                            {subDomain && (
                              <div className="flex items-center gap-2 mt-2">
                                <div className="animation-ctn">
                                  <div className="icon icon--order-cancel svg">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="28px"
                                      height="28px"
                                      viewBox="0 0 154 154"
                                    >
                                      <g
                                        fill="none"
                                        stroke="#FF4E4E"
                                        strokeWidth="2"
                                      >
                                        <circle
                                          cx="77"
                                          cy="77"
                                          r="72"
                                          style={{
                                            strokeDasharray: '480px, 480px',
                                            strokeDashoffset: '960px',
                                          }}
                                        ></circle>
                                        <circle
                                          id="colored-cancel"
                                          fill="#FF4E4E"
                                          cx="77"
                                          cy="77"
                                          r="72"
                                          style={{
                                            strokeDasharray: '480px, 480px',
                                            strokeDashoffset: '960px',
                                          }}
                                        ></circle>
                                        <line
                                          className="st0"
                                          stroke="#fff"
                                          strokeWidth="15"
                                          x1="48"
                                          y1="48"
                                          x2="106"
                                          y2="106"
                                          style={{
                                            strokeDasharray: '100px, 100px',
                                            strokeDashoffset: '200px',
                                            strokeLinejoin: 'round',
                                            strokeLinecap: 'round',
                                          }}
                                        />
                                        <line
                                          className="st0"
                                          stroke="#fff"
                                          strokeWidth="15"
                                          x1="106"
                                          y1="48"
                                          x2="48"
                                          y2="106"
                                          style={{
                                            strokeDasharray: '100px, 100px',
                                            strokeDashoffset: '200px',
                                            strokeLinejoin: 'round',
                                            strokeLinecap: 'round',
                                          }}
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <span className="text-sm font-medium text-red-500">
                                  Sub Domain is not available
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <button
              type="submit"
              className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 
                hover:shadow-2xl mt-8 px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm 
                ${loading || !isSubDomainAvailable || !isValid ? 'opacity-70' : ''}`}
              disabled={loading || !isSubDomainAvailable || !isValid}
            >
              {loading && <RotatingLinesLoader color="white" width="24" />}{' '}
              Confirm
            </button>
          </form>
        </div>
      </div>

      <div className="mt-auto">
        <FooterComponent />
      </div>
    </div>
  );
};

export default AccountSetUpPage;
