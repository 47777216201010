/* eslint-disable max-len */
const PrivacyPolicy = () => {
  return (
    <div
      className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md border-gray-300 outline-none focus:border-primary resize-none overflow-auto h-64`}
    >
      <p>
        At NodeReady, we prioritize your privacy and are committed to protecting
        your personal information. This Privacy Policy outlines the types of
        data we collect, how we use it, and your rights regarding your
        information.
      </p>

      <h2>
        <b>Data Collection</b>
      </h2>
      <li>
        We collect information you provide (e.g., name, email) and data from
        your usage of our site (cookies, IP addresses).
      </li>

      <h2>
        <b>Usage of Data</b>
      </h2>
      <li>
        Your information is used to improve site functionality, provide
        services, and communicate with you.
      </li>

      <h2>
        <b>Data Sharing</b>
      </h2>
      <li>
        We do not sell your data. Third parties may have access only for
        essential services (e.g., payment processing).
      </li>

      <h2>
        <b>Security</b>
      </h2>
      <li>We implement security measures to protect your data.</li>

      <h2>
        <b>Your Rights</b>
      </h2>
      <li>You can request access to or deletion of your data at any time.</li>

      <p>
        By using our site, you agree to this Privacy Policy. For any concerns,
        please contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
