/* eslint-disable camelcase */
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import useAuthStore from '../../store/AuthenticationStore';
import { UserDetails } from '../../types/UserDetails.type';
import {
  FetchUserAttributesOutput,
  updateUserAttributes,
} from '@aws-amplify/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EditProfileFormType } from '../../types/Forms.type';
import { errorMessages, successMessages } from '../../constants/messages';
import { toast } from 'react-toastify';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';

interface EditProfileModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfileModal = (props: EditProfileModalProps) => {
  const { visible, setVisible } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<EditProfileFormType>();

  const { setUserDetails, userDetails } = useAuthStore() as {
    setUserDetails: (
      _newDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    userDetails: UserDetails;
  };

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
  });

  const watchedFields = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    companyName: watch('companyName'),
  };

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails) {
      const {
        given_name = '',
        family_name = '',
        'custom:organization_name': companyName = '',
      } = userDetails;
      setInitialValues({
        firstName: given_name,
        lastName: family_name,
        companyName,
      });
      setValue('firstName', given_name);
      setValue('lastName', family_name);
      setValue('companyName', companyName);
    }
  }, [visible, userDetails, setValue]);

  const hasChanges = Object.keys(initialValues).some(
    field =>
      initialValues[field as keyof EditProfileFormType] !==
      watchedFields[field as keyof EditProfileFormType]
  );

  const isEmptyField = Object.values(watchedFields).some(value => !value);

  const onUpdateUserDetails: SubmitHandler<EditProfileFormType> = async (
    userData: EditProfileFormType
  ) => {
    if (!hasChanges) return;

    setLoading(true);

    const { firstName, lastName, companyName } = userData;
    const userAttributes = {
      given_name: firstName,
      family_name: lastName,
      'custom:organization_name': companyName,
    };

    try {
      await updateUserAttributes({ userAttributes });

      setUserDetails({
        ...userDetails,
        ...userAttributes,
      });

      toast.success(successMessages.UPDATED_PROFILE_SUCCESS_MESSAGE);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

      toast.error(errorMessage);
    } finally {
      setVisible(false);
      setLoading(false);
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      dismissableMask
      pt={{
        root: {
          className: 'border-none shadow-lg',
        },
        content: {
          className: 'bg-light-color padding-none rounded-lg',
        },
      }}
      style={{ width: '40vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      showHeader={false}
    >
      <div className="flex justify-between items-center shadow px-6 py-4">
        <h1 className="text-xl font-extrabold text-gray-900 font-roboto-slab">
          Edit Profile
        </h1>

        <button
          type="button"
          className="hover:bg-gray-200 text-lg size-10 hover:shadow-lg rounded-full flex items-center justify-center"
          onClick={() => {
            setVisible(false);
          }}
        >
          <i className="pi pi-times"></i>
        </button>
      </div>

      <form className="px-6 py-4" onSubmit={handleSubmit(onUpdateUserDetails)}>
        <div className="py-6">
          <p className="text-sm text-gray-700 font-medium">
            First Name <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none border-gray-300 focus:border-primary`}
            type="text"
            {...register('firstName', { required: 'First name is required' })}
            id="firstName"
          />
          {errors.firstName && (
            <p className="text-red-500 text-sm">{errors.firstName.message}</p>
          )}
        </div>

        <div className="pb-6">
          <p className="text-sm text-gray-700 font-medium">
            Last Name <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none border-gray-300 focus:border-primary`}
            type="text"
            {...register('lastName', { required: 'Last name is required' })}
            id="lastName"
          />
          {errors.lastName && (
            <p className="text-red-500 text-sm">{errors.lastName.message}</p>
          )}
        </div>

        <div className="pb-6">
          <p className="text-sm text-gray-700 font-medium">
            Company Name <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none border-gray-300 focus:border-primary`}
            type="text"
            {...register('companyName', {
              required: 'Company name is required',
            })}
            id="companyName"
          />
          {errors.companyName && (
            <p className="text-red-500 text-sm">{errors.companyName.message}</p>
          )}
        </div>

        <div className="flex justify-end items-center gap-4 mt-4 mb-2">
          <button
            type="button"
            className="border-primary border-[2px] text-primary px-4 py-2 rounded-lg font-medium shadow-md hover:bg-border-600 
                hover:shadow-2xl transition-all duration-500"
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </button>

          <button
            type="submit"
            className={`bg-primary text-white border-[2px] border-primary px-4 py-2 rounded-lg font-medium shadow-md
                   hover:bg-orange-500 hover:shadow-2xl transition-all duration-500 flex items-center justify-center gap-4
                   ${!hasChanges || isEmptyField || loading ? 'opacity-70' : 'opacity-1'}`}
            disabled={!hasChanges || isEmptyField || loading}
          >
            {loading ? (
              <>
                <RotatingLinesLoader color="white" width="24" />
                Updating...
              </>
            ) : (
              'Update'
            )}
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default EditProfileModal;
