export const errorMessages = {
  UNAUTHORIZED_LOGIN: 'unAuthorized login',
  API_REQUEST_ERROR: 'An error occurred while sending the request.',
  NETWORK_ERROR: 'Network error: Please check your internet connection.',
  ONLY_IMAGE_FILES_ERROR: 'Only image files can be uploaded!',
  UNKNOWN_ERROR: 'An unknown error occurred',
};

export const successMessages = {
  SUCCESS_LOGIN: 'Login success!',
  PASSWORD_RESET_SUCCESS_MESSAGE:
    'Password reset successful, you can now sign in',
  VERIFICATION_CODE_RESENT_SUCCESS_MESSAGE:
    'Verification code resent to your email',
  SIGN_UP_SUCCESS_MESSAGE: 'Sign Up successful, you can now sign in',
  CHANGE_NEW_PASSWORD: 'Please change your password to sign in.',
  ACCOUNT_SETUP_SUCCESS_MESSAGE: 'Account setup successful',
  UPDATED_PASSWORD_SUCCESS_MESSAGE: 'Password updated successfully!',
  GOOGLE_ACCOUNT_SETUP_SUCCESS_MESSAGE:
    'Your account setup is complete. Please log in again to continue.',
  UPDATED_PROFILE_SUCCESS_MESSAGE: 'Profile updated successfully!',
};

export const DIALOG_REDIRECT_MESSAGE = `You will be redirected to ${
  import.meta.env.VITE_PAYMENT_SERVICE_URL
} upon clicking on the continue button.`;
