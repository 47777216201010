export const defaultTimerMinutes = 119;
export const defaultTimerInterval = 1000;
export const defaultInstanceExecutionTimer = 10000;
export const defaultTimerForSubDomainChecker = 500;
export const reDirectionTimer = 5;
export const activePaymentInformationTab = 2;
export const activePlansInformationTab = 3;
export const subDomainValidityMinimumLength = 3;
export const passwordRegexValue =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
export const passwordValidationErrorMessage =
  'Password must be at least 12 characters long and include at least one uppercase letter, ' +
  'one lowercase letter, one number, and one special character';
