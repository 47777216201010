import moment, { Moment } from 'moment';

const dateFormat = 'M/DD/YYYY';
const dateTimeFormat = 'M/DD/YYYY HH:mm';
const timeFormat = 'HH:mm';
const hourSymbol = 'h';
const dateFormats = [
  'dd-MMMM-yyyy',
  'YYYY-MM-DD',
  'dd.MM.yyyy',
  'MM/dd/yyyy',
  'shortDate',
];
const dateOptions = { formatYear: 'yy', startingDay: 1, showWeeks: true };
const altInputFormats = ['M!/d!/yyyy'];
const longDateFormat = 'L';

export function addHoursToTodaysDateTime(numberOfHours: number): string {
  return moment().add(numberOfHours, hourSymbol).format(dateTimeFormat);
}

export function addHoursToDateTime(
  numberOfHours: number,
  dateTime: Moment
): string {
  return dateTime.add(numberOfHours, hourSymbol).format(dateTimeFormat);
}

export function getTimeFromDateTime(dateTime: string): string {
  return moment(dateTime).format(timeFormat);
}

export function formatDate(date: string): string {
  return moment(date).format(longDateFormat);
}

export function formatDateTime(dateTime: string): string {
  return moment(dateTime).format(dateTimeFormat);
}

export function formatDateAsPerType(
  dateTime: string,
  formatReq: string,
  requiredTimeZone: string
): string {
  const dd = new Date(dateTime);
  const today = dd.toLocaleString('en-US', { timeZone: requiredTimeZone });
  return moment.utc(today).format(formatReq);
}

export function formatDateAsPerLocalType(
  dateTime: string,
  formatReq: string,
  requiredTimeZone?: string
): string {
  const dd = new Date(dateTime);

  const timeZone =
    requiredTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  const today = dd.toLocaleString('en-US', { timeZone });

  return moment.utc(today).format(formatReq);
}

export function formatTime(date: string): string {
  return date === 'TBD' ? date : moment(date).format(timeFormat);
}

export function setHoursToDate(
  date: string,
  hours: number,
  arithmetic: 'a' | 's'
): string {
  if (arithmetic === 'a') {
    return moment(date, longDateFormat)
      .add(hours, hourSymbol)
      .format(longDateFormat);
  } else {
    return moment(date, longDateFormat)
      .subtract(hours, hourSymbol)
      .format(longDateFormat);
  }
}

export function getTodaysDate(): string {
  return moment().format(longDateFormat);
}

export function getTodaysTime(): string {
  return moment().format(timeFormat);
}

export function getTodaysDateTime(): string {
  return moment().format(dateTimeFormat);
}

export function getDateFormats(): string[] {
  return dateFormats;
}

export function getDateOptions() {
  return dateOptions;
}

export function getAltInputFormats(): string[] {
  return altInputFormats;
}

export function getDateParts(date: string): {
  year: number;
  day: number;
  month: number;
} {
  const d = moment(date, longDateFormat);
  return { year: d.year(), day: d.date(), month: d.month() };
}

export function getDatePartsFormat(
  date: string,
  dateFormat: string
): { year: number; day: number; month: number } {
  const d = moment(date, dateFormat);
  return { year: d.year(), day: d.date(), month: d.month() };
}

export function getDifference(
  start: string,
  end: string,
  measurement: moment.unitOfTime.Diff
): number {
  const startDate = moment(start, longDateFormat);
  const endDate = moment(end, longDateFormat);
  return endDate.diff(startDate, measurement);
}

export function formatDateYear(date: string): string {
  return moment(date).format(dateFormats[1]);
}

export function formatMomentDateYear(): string {
  return moment().format(dateFormats[1]);
}

export function isAfter(dateTime: string): boolean {
  return moment().isAfter(dateTime);
}

export function defaultDateFormat(
  date: string,
  toFormat: string = dateFormat
): string {
  return moment(date, longDateFormat).format(toFormat);
}

export function setLocale(locale: string): void {
  moment.locale(locale);
}
