/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuthStore from '../store/AuthenticationStore';
import RotatingLinesLoader from '../common/components/spinners/RotatingLinesLoader';
import Lottie from 'lottie-react';
import stackBuildingAnimation from '../assets/lottieFiles/building-stack-lottie-animation.json';
import GenericLayout from '../common/layout/GenericLayout';
import {
  defaultInstanceExecutionTimer,
  defaultTimerInterval,
  reDirectionTimer,
} from '../constants/DefaultValues';
import successAnimation from '../assets/lottieFiles/success-lottie-animation.json';
import failedAnimation from '../assets/lottieFiles/failed-lottie-animation.json';
import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from '@aws-amplify/auth';
import { UserDetails } from '../types/UserDetails.type';
import { getInstanceProvisioningJob } from '../api/instance-provisioning-jobs';
import { InstanceProvisioningJobsResponseType } from '../types/InstanceProvisioningJobs.type';

const InstanceProvisioningJobsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>();
  const [job, setJob] = useState<InstanceProvisioningJobsResponseType>();

  const [redirectCountdown, setRedirectCountdown] = useState(reDirectionTimer);

  const { setUserDetails, authToken, userDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    authToken: string;
    userDetails: UserDetails;
  };

  async function getUpdatedUserAttributes() {
    const updatedUserAttributes: any = await fetchUserAttributes();

    setUserDetails({ ...userDetails, ...updatedUserAttributes });
  }

  useEffect(() => {
    getUpdatedUserAttributes();
  }, []);

  useEffect(() => {
    const getInstanceProvisioningJobStatus = async () => {
      setLoading(true);
      try {
        const response = await getInstanceProvisioningJob(id, authToken);
        setJob(response);
      } catch (error) {
        setJob(undefined);
      } finally {
        setLoading(false);
      }
    };

    const interval = setInterval(async () => {
      if (
        !['COMPLETED', 'COMPLETE', 'FAILED', 'FAIL'].includes(job?.state ?? '')
      ) {
        await getInstanceProvisioningJobStatus();
      } else {
        clearInterval(interval);
      }
    }, defaultInstanceExecutionTimer);

    getInstanceProvisioningJobStatus();

    return () => clearInterval(interval);
  }, [id, authToken, job?.state]);

  useEffect(() => {
    if (
      ['COMPLETED', 'COMPLETE', 'FAILED', 'FAIL'].includes(job?.state ?? '')
    ) {
      const interval = setInterval(() => {
        setRedirectCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            window.location.href = '/?isNavigated=true';
            return 0;
          }
          return prevCountdown - 1;
        });
      }, defaultTimerInterval);

      return () => clearInterval(interval);
    }
  }, [job]);

  return (
    <GenericLayout>
      <div className="flex flex-col flex-1 h-screen items-center justify-center">
        {!job && loading && <RotatingLinesLoader />}

        {job && (job.state === 'NEW' || job.state === 'IN_PROGRESS') && (
          <>
            <div className="w-[50vw] lg:w-[30vw]">
              <Lottie animationData={stackBuildingAnimation} />
            </div>
            <p className="mt-10 font-bold text-xl">
              We're building your stack. This might take a moment, so please
              bear with us!
            </p>
          </>
        )}

        {job && job.state === 'COMPLETE' && (
          <div className="flex flex-col items-center">
            <div className="w-[40vw] lg:w-[20vw]">
              <Lottie animationData={successAnimation} loop={false} />
            </div>
            <p className="font-bold text-center text-xl text-gray-600">
              Success! Your stack is built and ready to go.
            </p>
            <p className="text-center font-medium mt-3 text-gray-600">
              Redirecting you in just{' '}
              <span className="text-black">{redirectCountdown}</span> seconds!
            </p>
          </div>
        )}

        {job && job.state === 'FAILED' && (
          <div className="flex flex-col items-center">
            <div className="w-[40vw] lg:w-[20vw]">
              <Lottie animationData={failedAnimation} loop={false} />
            </div>
            <p className="font-bold text-xl text-red-600">
              Oops! Something went wrong with building your stack. Please try
              again.
            </p>
          </div>
        )}
      </div>
    </GenericLayout>
  );
};

export default InstanceProvisioningJobsPage;
