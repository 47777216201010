import { Dialog } from 'primereact/dialog';
import React from 'react';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';

interface PaymentIntentModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle: string;
  dialogMessage: string;
  loadingDialog: boolean;
  showDialogConfirmButton: boolean;
  onClickDialogConfirmButton: () => void;
}

const PaymentIntentModal = (props: PaymentIntentModalProps) => {
  const {
    visible,
    setVisible,
    dialogTitle,
    dialogMessage,
    loadingDialog,
    showDialogConfirmButton,
    onClickDialogConfirmButton,
  } = props;

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      dismissableMask={false}
      pt={{
        root: {
          className: 'border-none shadow-lg',
        },
        content: {
          className: 'bg-light-color padding-none rounded-lg',
        },
      }}
      style={{ width: '40vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      showHeader={false}
    >
      {loadingDialog ? (
        <div className="p-6 h-[45vh] flex flex-col gap-2 items-center justify-center">
          <RotatingLinesLoader width="64" />
          <div className="text-gray-700 pt-5 text-lg">
            <div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
          </div>
        </div>
      ) : (
        <div className="p-6">
          <h1 className="text-xl font-semibold capitalize">{dialogTitle}</h1>

          <p className="text-gray-500 pt-5">{dialogMessage}</p>

          <div className="flex justify-end items-center gap-4 mt-10 mb-2">
            <button
              type="button"
              className="border-primary border-[2px] text-primary px-4 py-2 rounded-lg font-medium shadow-md hover:bg-border-600 
                hover:shadow-2xl transition-all duration-500"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </button>

            {showDialogConfirmButton && (
              <button
                type="button"
                className={`bg-primary text-white border-[2px] border-primary px-4 py-2 rounded-lg font-medium shadow-md
                   hover:bg-orange-600 hover:shadow-2xl transition-all duration-500`}
                onClick={onClickDialogConfirmButton}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default PaymentIntentModal;
