import { ToastContainer } from 'react-toastify';
import Routers from './routes';
import { Amplify } from 'aws-amplify';
import awsExports from './config/aws-exports';

const AWS_INSTANCE_LAMDA_API = import.meta.env.VITE_AWS_INSTANCE_API_URL;
const AWS_REGION = import.meta.env.VITE_AWS_COGNITO_REGION;

// eslint-disable-next-line camelcase
awsExports.aws_cloud_logic_custom = [
  {
    name: 'InstanceProvisioningApi',
    endpoint: AWS_INSTANCE_LAMDA_API,
    region: AWS_REGION,
    service: 'execute-api',
    userpool: true,
  },
];

awsExports.oauth = {
  ...awsExports.oauth,
  redirectSignIn: import.meta.env.VITE_COGNITO_REDIRECT_SIGN_IN,
  redirectSignOut: import.meta.env.VITE_COGNITO_REDIRECT_SIGN_OUT,
};

Amplify.configure(awsExports);

const App = () => {
  return (
    <>
      <Routers />
      <ToastContainer />
    </>
  );
};

export default App;
