/* eslint-disable camelcase */
import axiosInstance from '../../middleware/Middleware';

export const fetchPaymentDetails = async (customerId: string) => {
  return axiosInstance
    .get(`/customer/getCustomerPaymentMethods?id=${customerId}`)
    .then(async response => {
      return {
        data: response,
        message: 'Retrieved payment details successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve payment details, ${error.message}`);
    });
};

export const fetchCustomerPortalSession = async (
  customerId: string,
  reDirectUrl: string
) => {
  return axiosInstance
    .post(`/customer-portal/createCustomerPortalSession`, {
      customer: customerId,
      locale: 'en',
      return_url: reDirectUrl,
    })
    .then(async response => {
      return {
        data: response,
        message: 'Retrieved Customer Portal successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve Customer Portal, ${error.message}`);
    });
};
