import { FooterLogo } from '../../assets';

const FooterComponent = () => {
  const year = new Date().getFullYear();

  return (
    <div className="bg-sky-950 px-12 py-8 flex flex-col gap-4 items-center justify-center">
      <img src={FooterLogo} alt="" />
      <p className="text-gray-50 leading-6 ">
        © {year} Node-Ready, Inc. All Rights Reserved. |{' '}
        <span>Privacy Policy</span>
      </p>
    </div>
  );
};

export default FooterComponent;
