/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../../constants/navigators';
import useAuthStore from '../../store/AuthenticationStore';
import { UserDetails } from '../../types/UserDetails.type';
import { toast } from 'react-toastify';
import { errorMessages, successMessages } from '../../constants/messages';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';

const ServiceProviderLoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { setUserDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
  };

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const sessionResponse = await fetchAuthSession();

        const accessToken = sessionResponse?.tokens?.accessToken;

        if (accessToken) {
          const clientId = accessToken.payload?.client_id
            ? String(accessToken.payload.client_id)
            : '';

          const userAttributes: any = await fetchUserAttributes();

          if (!userAttributes) {
            throw new Error('Failed to fetch user attributes.');
          }

          const userDetailsWithClientId = {
            ...userAttributes,
            clientId,
            accessId: accessToken?.payload.username,
          };

          setUserDetails(userDetailsWithClientId);

          toast.success(successMessages.SUCCESS_LOGIN);

          const navigateTo =
            userDetailsWithClientId['custom:setup'] === undefined ||
            userDetailsWithClientId['custom:setup'] === 'false'
              ? navigators.NAVIGATE_TO_ACCOUNT_SETUP
              : navigators.NAVIGATE_TO_HOME;

          navigate(navigateTo);
        } else {
          navigate(navigators.NAVIGATE_TO_LOGIN);
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate, setUserDetails]);

  return (
    <div className="h-screen w-full flex items-center justify-center">
      {loading && <RotatingLinesLoader />}
    </div>
  );
};

export default ServiceProviderLoginPage;
