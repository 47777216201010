import Lottie from 'lottie-react';
import notFoundAnimation from '../assets/lottieFiles/not-found-lottie-animation.json';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { navigators } from '../constants/navigators';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full">
      <Lottie animationData={notFoundAnimation} />
      <div className="mt-12">
        <p className="text-center text-xl font-semibold">
          We’re sorry, but the page you’re looking for cannot be found.
        </p>
        <p className="text-center pt-2 text-sm text-gray-500 font-medium">
          Please check the URL or click the button below to return back.
        </p>
        <div className="flex items-center justify-center mt-8">
          <button
            type="button"
            onClick={() => {
              navigate(navigators.NAVIGATE_BACK);
            }}
            className={`bg-primary flex items-center justify-center gap-3 relative hover:bg-orange-500 
              hover:shadow-2xl px-4 py-3 w-32 text-white font-medium rounded-3xl shadow-sm`}
          >
            Go Back <FaArrowRightLong />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
