/* eslint-disable no-magic-numbers */
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const defaultUnMaskCharacter: number = 2;

export const maskValue = (
  value: string,
  unMaskCharacter: number = defaultUnMaskCharacter
) => {
  const stringValue = value?.toString() ?? '';
  const maskLength = stringValue.length - unMaskCharacter;
  const masked =
    stringValue.slice(0, maskLength).replace(/./g, '*') +
    stringValue.slice(-unMaskCharacter);
  return masked;
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
