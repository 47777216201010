import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { ResetPasswordFormType } from '../../types/Forms.type';
import { SubmitHandler, useForm } from 'react-hook-form';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';
import { FetchUserAttributesOutput, updatePassword } from '@aws-amplify/auth';
import { toast } from 'react-toastify';
import { successMessages } from '../../constants/messages';
import useAuthStore from '../../store/AuthenticationStore';
import { UserDetails } from '../../types/UserDetails.type';
import { maskValue } from '../../utils/reUsableFunctions';
import {
  passwordRegexValue,
  passwordValidationErrorMessage,
} from '../../constants/DefaultValues';

interface ChangePasswordModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const { visible, setVisible } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<ResetPasswordFormType>();

  const { setUserDetails, userDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    userDetails: UserDetails;
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (confirmPassword) trigger('confirmPassword');
    if (password) trigger('password');
  }, [password, confirmPassword, trigger]);

  const onPasswordChangeSubmit: SubmitHandler<ResetPasswordFormType> = (
    userData: ResetPasswordFormType
  ) => {
    setLoading(true);
    updatePassword({
      oldPassword: userData.currentPassword ?? '',
      newPassword: userData.confirmPassword,
    })
      .then(() => {
        userDetails.password = maskValue(userData.confirmPassword);
        setUserDetails(userDetails);
        toast.success(successMessages.UPDATED_PASSWORD_SUCCESS_MESSAGE);
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setVisible(false);
      }}
      dismissableMask
      pt={{
        root: {
          className: 'border-none shadow-lg',
        },
        content: {
          className: 'bg-light-color padding-none rounded-lg',
        },
      }}
      style={{ width: '40vw' }}
      breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      showHeader={false}
    >
      <div className="flex justify-between items-center shadow px-6 py-4">
        <h1 className="text-xl font-extrabold text-gray-900 font-roboto-slab">
          Change Password
        </h1>

        <button
          type="button"
          className="hover:bg-gray-200 text-lg size-10 hover:shadow-lg rounded-full flex items-center justify-center"
          onClick={() => {
            setVisible(false);
          }}
        >
          <i className="pi pi-times"></i>
        </button>
      </div>

      <form
        className="px-6 py-4"
        onSubmit={handleSubmit(onPasswordChangeSubmit)}
      >
        <div className="py-6">
          <p className="text-sm text-gray-700 font-medium">
            Current Password <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                ${errors.currentPassword ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
            {...register('currentPassword', {
              required: `Current Password is required`,
            })}
            id="currentPassword"
            type="password"
            placeholder=""
          />

          <p className="mt-1 text-sm">
            {errors.currentPassword && (
              <span className="text-red-500">
                {errors.currentPassword.message}
              </span>
            )}
          </p>
        </div>

        <div className="pb-6">
          <p className="text-sm text-gray-700 font-medium">
            New Password <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                ${errors.password ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
            {...register('password', {
              required: `New Password is required`,
              pattern: {
                value: passwordRegexValue,
                message: passwordValidationErrorMessage,
              },
            })}
            id="password"
            type="password"
            placeholder=""
          />

          <p className="mt-1 text-sm">
            {errors.password && (
              <span className="text-red-500">{errors.password.message}</span>
            )}
          </p>
        </div>

        <div className="pb-10">
          <p className="text-sm text-gray-700 font-medium">
            Confirm Password <span className="text-red-500">*</span>
          </p>

          <input
            className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                    ${errors.confirmPassword ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
            {...register('confirmPassword', {
              required: 'Password Confirmation is required',
              validate: value => value === password || 'Passwords do not match',
            })}
            id="confirmPassword"
            type="password"
            placeholder=""
          />

          {errors.confirmPassword && (
            <p className="text-sm text-red-500 mt-1">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>

        <button
          type="submit"
          className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 mb-6
              hover:shadow-2xl px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm ${loading && 'opacity-70'}`}
          disabled={loading}
        >
          {loading && <RotatingLinesLoader color="white" width="24" />}
          Confirm
        </button>
      </form>
    </Dialog>
  );
};

export default ChangePasswordModal;
