import { SubmitHandler, useForm } from 'react-hook-form';
import FooterComponent from '../../common/layout/FooterComponent';
import LoginBannerComponent from '../../common/layout/LoginBannerComponent';
import { ResetPasswordFormType } from '../../types/Forms.type';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForgotPasswordInformationStore } from '../../store/AuthenticationStore';
import { ForgotPasswordStoreType } from '../../types/UserDetails.type';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';
import {
  confirmResetPassword,
  resetPassword,
  confirmSignIn,
  signOut,
} from '@aws-amplify/auth';
import { toast } from 'react-toastify';
import { navigators } from '../../constants/navigators';
import { formatTime } from '../../utils/reUsableFunctions';
import {
  defaultTimerInterval,
  defaultTimerMinutes,
  passwordRegexValue,
  passwordValidationErrorMessage,
} from '../../constants/DefaultValues';
import { successMessages } from '../../constants/messages';

const ResetPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<ResetPasswordFormType>();

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const { forgotPasswordInformation } = useForgotPasswordInformationStore() as {
    forgotPasswordInformation: ForgotPasswordStoreType;
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState(defaultTimerMinutes);
  const [showCodeResendButton, setShowCodeResendButton] = useState(false);
  const [isChangeCurrentPassword, setIsChangeCurrentPassword] = useState(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchQuery = searchParams.get('isChangePassword');

    if (searchQuery) {
      setIsChangeCurrentPassword(true);
    } else {
      setIsChangeCurrentPassword(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (confirmPassword) trigger('confirmPassword');
    if (password) trigger('password');
  }, [password, confirmPassword, trigger]);

  useEffect(() => {
    if (!showCodeResendButton) {
      const timer = setTimeout(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, defaultTimerInterval);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    if (remainingTime === 0) {
      setShowCodeResendButton(true);
    }
  }, [remainingTime]);

  const handleSuccess = () => {
    toast.success(successMessages.PASSWORD_RESET_SUCCESS_MESSAGE);
    navigate(navigators.NAVIGATE_TO_LOGIN);
    setLoading(false);
  };

  const handleError = (error: Error) => {
    toast.error(error.message);
    setLoading(false);
  };

  const updateToNewPassword = (userData: ResetPasswordFormType) => {
    confirmSignIn({ challengeResponse: userData.password })
      .then(() => {
        signOut();
        handleSuccess();
      })
      .catch(handleError);
  };

  const resetOldPassword = (userData: ResetPasswordFormType) => {
    confirmResetPassword({
      username: forgotPasswordInformation.email,
      confirmationCode: userData.verificationCode,
      newPassword: userData.password,
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  const onPasswordResetSubmit: SubmitHandler<ResetPasswordFormType> = (
    userData: ResetPasswordFormType
  ) => {
    setLoading(true);
    if (isChangeCurrentPassword) {
      updateToNewPassword(userData);
    } else {
      resetOldPassword(userData);
    }
  };

  const resendCode = () => {
    setShowCodeResendButton(false);
    setRemainingTime(defaultTimerMinutes);

    resetPassword({ username: forgotPasswordInformation.email })
      .then(() => {
        toast.success(successMessages.VERIFICATION_CODE_RESENT_SUCCESS_MESSAGE);
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <section className="h-screen w-full flex overflow-hidden">
        <form
          onSubmit={handleSubmit(onPasswordResetSubmit)}
          className="lg:w-[45%] w-full flex flex-col justify-center px-6 md:px-24 py-12 bg-primary-body h-full"
        >
          <div>
            <h1 className="text-3xl font-extrabold text-gray-900 font-roboto-slab">
              Reset Password
            </h1>

            {!isChangeCurrentPassword && (
              <div className="pt-10">
                <p className="text-sm text-gray-700 font-medium">
                  Confirmation Code
                </p>

                <input
                  className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                            ${errors.verificationCode ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                  {...register('verificationCode', {
                    required: 'Confirmation Code is required',
                  })}
                  id="confirmationCode"
                  type="text"
                  placeholder=""
                />

                <p className="mt-1 text-sm">
                  {errors.verificationCode ? (
                    <span className="text-red-500">
                      {errors.verificationCode.message}
                    </span>
                  ) : (
                    <span className="text-gray-500 text-sm">
                      To Reset Password, enter the code we emailed to &nbsp;
                      {
                        forgotPasswordInformation.nextStep.codeDeliveryDetails
                          .destination
                      }
                    </span>
                  )}
                </p>
              </div>
            )}

            <div className="py-6">
              <p className="text-sm text-gray-700 font-medium">
                {isChangeCurrentPassword ? 'New Password' : 'Password'}
              </p>

              <input
                className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                ${errors.password ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                {...register('password', {
                  required: `${isChangeCurrentPassword ? 'New Password' : 'Password'} is required`,
                  pattern: {
                    value: passwordRegexValue,
                    message: passwordValidationErrorMessage,
                  },
                })}
                id="password"
                type="password"
                placeholder=""
              />

              <p className="mt-1 text-sm">
                {errors.password && (
                  <span className="text-red-500">
                    {errors.password.message}
                  </span>
                )}
              </p>
            </div>

            <div className="pb-10">
              <p className="text-sm text-gray-700 font-medium">
                Confirm Password
              </p>

              <input
                className={`bg-white mt-1 px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                    ${errors.confirmPassword ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                {...register('confirmPassword', {
                  required: 'Password Confirmation is required',
                  validate: value =>
                    value === password || 'Passwords do not match',
                })}
                id="confirmPassword"
                type="password"
                placeholder=""
              />

              {errors.confirmPassword && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>

            <button
              type="submit"
              className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 
              hover:shadow-2xl px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm ${loading && 'opacity-70'}`}
              disabled={loading}
            >
              {loading && <RotatingLinesLoader color="white" width="24" />}
              Confirm
            </button>

            {!isChangeCurrentPassword && (
              <>
                <button
                  type="button"
                  className={`mt-6 relative hover:shadow-2xl px-4 py-3 w-full font-medium rounded-3xl shadow-sm
              ${
                showCodeResendButton
                  ? 'border-[2px] border-primary hover:border-orange-500 text-primary'
                  : 'bg-primary text-white opacity-70'
              }`}
                  onClick={resendCode}
                  disabled={!showCodeResendButton}
                >
                  Resend Code
                </button>

                <span className="text-gray-500 text-sm p-4">
                  Resend Code in {formatTime(remainingTime)}
                </span>
              </>
            )}
          </div>
        </form>
        <div className=" w-[55%] relative lg:flex hidden">
          <LoginBannerComponent />
        </div>
      </section>

      <section>
        <FooterComponent />
      </section>
    </>
  );
};

export default ResetPasswordPage;
