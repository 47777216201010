/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import TableComponent from '../../common/components/table/TableComponent';
import { plansInformationTableHeader } from '../../constants/tableHeader.constant';
import useAuthStore from '../../store/AuthenticationStore';
import usePlansInformationStore from '../../store/PlansInformation.Store';
import { PlansInformationType } from '../../types/PlansInformation.type';
import { getInstances } from '../../api/instances';
import { useLoaderStore } from '../../store/LoaderStore';
import { createInstanceProvisioningJob } from '../../api/instance-provisioning-jobs';
import {
  DIALOG_REDIRECT_MESSAGE,
  errorMessages,
} from '../../constants/messages';
import PaymentIntentModal from './PaymentIntentModal';
import {
  defaultTimerInterval,
  reDirectionTimer,
} from '../../constants/DefaultValues';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'primereact/tooltip';

const PlansInformationComponent = () => {
  const { authToken } = useAuthStore() as {
    authToken: string;
  };

  const { plansInformation, setPlansInformation } =
    usePlansInformationStore() as {
      plansInformation: PlansInformationType[];
      setPlansInformation: (
        _newPlansInformation: PlansInformationType[]
      ) => void;
    };

  const { toggleGlobalLoader, isLoading } = useLoaderStore() as {
    toggleGlobalLoader: (_state: boolean) => void;
    isLoading: boolean;
  };

  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [showDialogConfirmButton, setShowDialogConfirmButton] = useState(true);
  const [redirectCountdown, setRedirectCountdown] = useState(reDirectionTimer);

  const navigate = useNavigate();

  const loadInstances = async () => {
    toggleGlobalLoader(true);
    try {
      const instances = await getInstances(authToken);
      setPlansInformation(instances);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;
      toast.error(errorMessage);
    } finally {
      toggleGlobalLoader(false);
    }
  };

  useEffect(() => {
    loadInstances();
  }, [authToken, setPlansInformation]);

  const onClickNewInstance = async () => {
    try {
      setShowDialog(true);
      setDialogMessage('Provisioning an Instance');
      setLoadingDialog(true);
      const response = await createInstanceProvisioningJob(authToken);
      navigate(`/instance-provisioning-jobs/${response.id}`);
    } catch (error: any) {
      if (error?.response?.data?.code !== '004') {
        setDialogTitle(error ?? 'unknown error');
        setDialogMessage(DIALOG_REDIRECT_MESSAGE);
      } else {
        setDialogTitle('Account does not have a subscription');
        setDialogMessage(DIALOG_REDIRECT_MESSAGE);
        setShowDialogConfirmButton(true);
      }
    } finally {
      setLoadingDialog(false);
    }
  };

  const onClickDialogConfirmButton = () => {
    setLoadingDialog(true);
    setDialogTitle('');
    setDialogMessage(
      `You will be redirected in <strong>${redirectCountdown}</strong> seconds...`
    );

    const interval = setInterval(() => {
      setRedirectCountdown(prevCountdown => {
        const newCountdown = prevCountdown - 1;
        if (newCountdown === 0) {
          clearInterval(interval);
          window.location.href = `${import.meta.env.VITE_PAYMENT_SERVICE_URL}?access_token=${authToken}`;
        }
        setDialogMessage(
          `You will be redirected in <strong>${newCountdown}</strong> seconds...`
        );
        return newCountdown;
      });
    }, defaultTimerInterval);
  };

  const onAccessInstanceClick = (instanceValues: any) => {
    if (instanceValues.state === 'AVAILABLE') {
      window.open(
        `https://forge.${instanceValues.subdomain}.${import.meta.env.VITE_INSTANCE_URL}`,
        '_blank'
      );
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="tracking-[0.6px]">Plans</p>

        <div className="flex items-center gap-4">
          {plansInformation.length > 0 && (
            <button
              type="button"
              className={`border-[1px] border-primary text-primary px-4 py-2 rounded-lg font-medium shadow-md hover:border-orange-600 
                      hover:shadow-2xl transition-all duration-500`}
              onClick={loadInstances}
              data-pr-tooltip="No notifications"
              data-pr-position="right"
              title="Refresh"
            >
              <Tooltip target=".refresh-icon" />
              <i
                className={`pi pi-refresh ${isLoading ? 'animate-spin' : ''} refresh-icon`}
              ></i>
            </button>
          )}

          <button
            type="button"
            className={`bg-primary border-[1px] border-primary text-white px-4 py-2 rounded-lg font-medium shadow-md hover:bg-orange-600 
            hover:border-orange-600 hover:shadow-2xl transition-all duration-500 
            ${isLoading || plansInformation.length > 0 ? 'opacity-70' : 'opacity-100'}`}
            disabled={isLoading || plansInformation.length > 0}
            onClick={onClickNewInstance}
          >
            Select New Plan
          </button>
        </div>
      </div>

      {!isLoading &&
        (plansInformation.length > 0 ? (
          <div className="mt-8">
            <TableComponent
              tableHeader={plansInformationTableHeader}
              tableData={plansInformation}
              showEditOption={false}
              showDeleteOption={false}
              showRedirectButton={true}
              onAccessInstanceClick={onAccessInstanceClick}
            />
          </div>
        ) : (
          <div className="mt-20 flex-1 w-full text-center">
            <p className="text-lg font-semibold">
              **Looks like you don't have a plan selected yet!**
            </p>
            <p className="mt-4 text-gray-600">
              Explore our options and find the perfect fit for your needs. Check
              out our available plans below and get started today!
            </p>
          </div>
        ))}

      {showDialog && (
        <PaymentIntentModal
          visible={showDialog}
          setVisible={setShowDialog}
          dialogTitle={dialogTitle}
          dialogMessage={dialogMessage}
          loadingDialog={loadingDialog}
          showDialogConfirmButton={showDialogConfirmButton}
          onClickDialogConfirmButton={onClickDialogConfirmButton}
        />
      )}
    </>
  );
};

export default PlansInformationComponent;
