/* eslint-disable max-len */
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { SignUpFormType } from '../../types/Forms.type';
import PrivacyPolicy from './PrivacyPolicy';

interface PersonalInformationProps {
  setValue: UseFormSetValue<SignUpFormType>;
  register: UseFormRegister<SignUpFormType>;
  trigger: UseFormTrigger<SignUpFormType>;
  errors: FieldErrors<SignUpFormType>;
}

const PersonalInformationForm = (props: PersonalInformationProps) => {
  const { setValue, register, trigger, errors } = props;

  return (
    <>
      <div className="my-8">
        <h1 className="font-medium text-lg text-gray-900">
          Personal information
        </h1>
        <p className="text-gray-500">
          Copy here about not publicly sharing personal information.
        </p>
      </div>

      {/* <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Company<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <input
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
                  ${errors.company ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
            id="company"
            type="text"
            {...register('company', {
              required: 'Company is required',
            })}
          />
          {errors.company && (
            <p className="text-sm text-red-500 mt-1">
              {errors.company.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Sub Domain<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <div className="flex bg-white shadow-sm w-full rounded-md">
            <input
              className={`px-4 py-2 w-full rounded-l-md  rounded-r-none outline-none border-[1px]
                ${errors.subDomain ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
              id="subDomain"
              type="text"
              {...register('subDomain', {
                required: 'sub Domain is required',
              })}
            />
            <span className="px-4 py-2 rounded-r border-[1px] border-l-0 border-gray-300 whitespace-nowrap bg-gray-200">
              .node-ready.com
            </span>
          </div>
          {errors.subDomain && (
            <p className="text-sm text-red-500 mt-1">
              {errors.subDomain.message}
            </p>
          )}
        </div>
      </div> */}

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          First Name<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <input
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
                  ${errors.firstName ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
            id="firstName"
            type="text"
            {...register('firstName', {
              required: 'First Name is required',
            })}
          />
          {errors.firstName && (
            <p className="text-sm text-red-500 mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Last Name<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <input
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
                  ${errors.lastName ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
            id="lastName"
            type="text"
            {...register('lastName', {
              required: 'Last Name is required',
            })}
          />
          {errors.lastName && (
            <p className="text-sm text-red-500 mt-1">
              {errors.lastName.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Email Address<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <input
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none 
                  ${errors.email ? 'border-red-500 focus:red-500' : 'border-gray-300 focus:border-primary'}`}
            id="email"
            type="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Please enter a valid email address',
              },
            })}
          />
          {errors.email && (
            <p className="text-sm text-red-500 mt-1">{errors.email.message}</p>
          )}
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:gap-10 gap-2 py-6 border-b-[1px] border-gray-200">
        <p className="lg:w-[30%] w-full text-gray-700">
          Phone Number<span className="text-red-500">*</span>
        </p>

        <div className="w-full flex flex-col lg:mx-20">
          <PhoneInput
            country={'us'}
            autoFormat={false}
            enableSearch
            inputProps={{
              name: 'phone',
              required: true,
            }}
            onChange={(phone: string) => {
              setValue('phone', phone);
              trigger('phone');
            }}
            inputClass="phone-number-input"
          />
          {errors.phone && (
            <p className="text-sm text-red-500 mt-1">
              Phone number is required
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 py-6">
        <p className="lg:w-[30%] w-full text-gray-700">
          End-user license agreement
        </p>

        <div className="w-full flex flex-col">
          <PrivacyPolicy />
          {/* <textarea
            className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md border-gray-300 outline-none focus:border-primary resize-none overflow-auto h-64`}
            readOnly
          /> */}
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="bg-white accent-primary shadow-sm border-[1px] border-gray-300 h-4 w-4 outline-none focus:border-primary"
            {...register('termsAndConditions', {
              required: 'You must accept the terms and conditions',
            })}
          />
          <p
            className={`text-sm ${errors.termsAndConditions ? 'text-red-500' : 'text-gray-700'}`}
          >
            I have read and agree to the policy
          </p>
        </div>
        {errors.termsAndConditions && (
          <p className="text-xs text-red-500 mt-1">
            {errors.termsAndConditions.message} *
          </p>
        )}
      </div>
    </>
  );
};

export default PersonalInformationForm;
