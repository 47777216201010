/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'aws-amplify/api';
import { statusCodes } from '../../constants/statusCodes';

async function getInstance(id: number, authToken: string): Promise<any> {
  try {
    const restOperation = get({
      apiName: 'InstanceProvisioningApi',
      path: `/instances/${id}`,
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (err: any) {
    if (err?.response?.status !== statusCodes.HTTP_STATUS_NOT_FOUND) {
      throw err;
    }
  }
}

async function getInstances(authToken: string): Promise<any> {
  try {
    const restOperation = get({
      apiName: 'InstanceProvisioningApi',
      path: `/instances`,
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    return response;
  } catch (err: any) {
    if (err?.response?.status !== statusCodes.HTTP_STATUS_NOT_FOUND) {
      throw err;
    }
  }
}

export { getInstance, getInstances };
