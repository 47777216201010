import { create } from 'zustand';
import { PlansInformationType } from '../types/PlansInformation.type';

export interface PlanInformationStoreProps {
  plansInformation: PlansInformationType[];
  setPlansInformation: (_newPlansInformation: PlansInformationType[]) => void;
}

const initialState = {
  plansInformation: [] as PlansInformationType[],
};

const usePlansInformationStore = create<PlanInformationStoreProps>(set => ({
  ...initialState,
  setPlansInformation: (newPlansInformation: PlansInformationType[]): void => {
    set({ plansInformation: newPlansInformation });
  },
}));

export default usePlansInformationStore;
