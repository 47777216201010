import { ReactNode } from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import RotatingLinesLoader from '../components/spinners/RotatingLinesLoader';
import { useLoaderStore } from '../../store/LoaderStore';

interface GenericLayoutProps {
  children: ReactNode;
  showSignIn?: boolean;
}

const GenericLayout = (props: GenericLayoutProps) => {
  const { children, showSignIn } = props;

  const { isLoading } = useLoaderStore();

  return (
    <div className="relative">
      {isLoading && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <RotatingLinesLoader color="white" />
        </div>
      )}

      <div className="fixed w-full header-styles">
        <HeaderComponent showSignIn={showSignIn} />
      </div>
      <div className="pt-[4rem] min-h-screen nr-scrollbar">{children}</div>
      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default GenericLayout;
