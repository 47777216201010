/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';

import App from './App.tsx';
import './index.css';
import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      instrumentPageLoad: true,
      instrumentNavigation: true,
      markBackgroundSpan: true,
      enableLongTask: true,
      traceFetch: true,
      traceXHR: true,
      enableHTTPTimings: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true,
      sessionSampleRate: 1,
      errorSampleRate: 1.0,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag('aws_account_id', import.meta.env.VITE_AWS_ACCOUNT_ID);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <PrimeReactProvider value={{ unstyled: true, pt: Tailwind, ripple: true }}>
      <App />
    </PrimeReactProvider>
  </BrowserRouter>
);
