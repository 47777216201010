/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'react';
import GenericLayout from '../common/layout/GenericLayout';
import { accountTabs } from '../constants/tabsData.constant';
import ProfileInformationComponent from '../components/homeComponents/ProfileInformationComponent';
import PaymentInformationComponent from '../components/homeComponents/PaymentInformationComponent';
import PlansInformationComponent from '../components/homeComponents/PlansInformationComponent';
import { useSearchParams } from 'react-router-dom';

const HomePage = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const [searchParams] = useSearchParams();

  const isNavigated = searchParams.get('isNavigated');

  const activeTabComponent = () => {
    switch (activeTab) {
      case 1: {
        return <ProfileInformationComponent />;
      }
      case 2: {
        return <PaymentInformationComponent setActiveTab={setActiveTab} />;
      }
      case 3: {
        return <PlansInformationComponent />;
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (isNavigated !== null && isNavigated) {
      setActiveTab(3);
    }
  }, [isNavigated]);

  return (
    <GenericLayout>
      <div className="h-[15rem] w-full bg-primary-body flex flex-col gap-3 justify-center items-start px-8">
        <p className=" text-5xl font-extrabold text-sky-950 font-roboto-slab">
          My Account
        </p>
        <p className="text-sky-950 text-xl">Manage your payment and plan.</p>
      </div>

      <div className="md:px-6 px-4 pt-12 pb-24 flex lg:flex-row flex-col">
        <div className="flex lg:flex-col lg:justify-normal gap-2 justify-between lg:items-start items-center flex-row w-full lg:w-[325px]">
          {accountTabs.map(tab => (
            <button
              key={tab.id}
              className={`flex items-center gap-4 p-2 w-full`}
              onClick={() => setActiveTab(tab.id)}
            >
              {' '}
              <tab.icon
                className={`${activeTab === tab.id ? 'text-primary' : 'text-gray-500'} hover:text-primary`}
                size={20}
              />{' '}
              <span className="font-medium md:text-lg">{tab.title}</span>
            </button>
          ))}
        </div>

        <div className="w-full lg:mt-0 mt-8">{activeTabComponent()}</div>
      </div>
    </GenericLayout>
  );
};

export default HomePage;
