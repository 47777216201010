import { LoginBanner } from '../../assets';

const LoginBannerComponent = () => {
  return (
    <>
      <img
        src={LoginBanner}
        alt="login"
        className="w-full h-screen bg-primary object-cover"
      />

      <div className="absolute">
        <div className="px-20 py-16">
          <p className="text-white font-extrabold text-6xl leading-[72px] font-roboto-slab ">
            Unlock the full power of Node-Ready{' '}
            <span className="bg-orange-300 rounded-[46px] text-4xl px-4 py-1">
              PRO
            </span>
          </p>

          <p className="mt-12 text-white text-lg leading-8">
            Now you can greatly accelerate development of solutions involving
            large arrays of systems, tools, and data sources to deliver seamless
            workflows – at scale.
          </p>

          <div className="mt-12 relative">
            <button
              onClick={() =>
                (window.location.href = 'https://www.node-ready.com')
              }
              className="border-[1px] border-white shadow hover:scale-105 px-6 py-2 text-white rounded-3xl"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginBannerComponent;
